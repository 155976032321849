.facetwp-facet {
    margin-bottom: 0px;
}

.facetwp-pager-label {
    display: inline-block;
    margin-right: 12px;
}

.facetwp-page {
    display: inline-block;
    padding: 0px 4px;
    margin-right: 6px;
    cursor: pointer;
}

.facetwp-page.active {
    font-weight: bold;
    cursor: default;
}

.facetwp-loading {
    width: 20px;
    height: 20px;
    background: url(../images/loading.png) no-repeat;
    background-size: 20px 20px;
    -webkit-animation: spin 700ms infinite linear;
    -moz-animation: spin 700ms infinite linear;
    animation: spin 700ms infinite linear;
}

.facetwp-overlay {
    position: absolute;
    background-color: #fff;
    opacity: 0.6;
}

/* Checkboxes */

.facetwp-type-checkboxes .facetwp-depth {
    display: none;
}

.facetwp-type-checkboxes .facetwp-depth.visible {
    display: inherit;
}

.facetwp-checkbox {
    background: url(../images/checkbox.png) 0 50% no-repeat;
    background-size: 14px 14px;
    margin-bottom: 4px;
    padding-left: 20px;
    cursor: pointer;
}

.facetwp-checkbox.checked {
    background-image: url(../images/checkbox-on.png);
}

.facetwp-checkbox.disabled,
.facetwp-radio.disabled {
    opacity: 0.4;
    cursor: default;
}

.facetwp-checkbox .facetwp-expand {
    float: right;
}

/* Radio */

.facetwp-radio {
    background: url(../images/radio.png) 0 50% no-repeat;
    background-size: 14px 14px;
    margin-bottom: 4px;
    padding-left: 20px;
    cursor: pointer;
}

.facetwp-radio.checked {
    background-image: url(../images/radio-on.png);
}

/* Date range */

.flatpickr-clear {
    text-align: center;
    padding: 5px 0;
    cursor: pointer;
}

/* fSelect */

.facetwp-type-fselect .fs-wrap,
.facetwp-type-fselect .fs-dropdown {
    width: 220px;
}

/* Hierarchy */

.facetwp-depth {
    margin-left: 12px;
}

.facetwp-link {
    cursor: pointer;
}

.facetwp-link.checked {
    font-weight: bold;
    cursor: default;
}

.facetwp-toggle {
    cursor: pointer;
}

.facetwp-hidden {
    display: none;
}

/* Slider */

.facetwp-slider-wrap {
    padding-bottom: 15px;
}

.facetwp-slider-reset {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    background: #fff;
    box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
    padding: 4px 8px;
    cursor: pointer;
}

.noUi-target {
    padding: 0 15px;
}

/* Search */

.facetwp-search-wrap {
    position: relative;
}

.facetwp-search {
    padding-right: 28px;
}

.facetwp-btn {
    top: 0;
    right: 5px;
    line-height: 1;
    position: absolute;
    cursor: pointer;
    opacity: 0.5;
}

.facetwp-btn:before {
    display: inline-block;
    content: '';
    width: 20px;
    height: 20px;
    background: url(../images/icon-search.png) no-repeat;
    background-size: 20px 20px;
}

.facetwp-btn.f-reset:before {
    background: url(../images/icon-close.png) no-repeat;
    background-size: 20px 20px;
}

/* Proximity */

#facetwp-location {
    padding-right: 28px;
}

.location-wrap {
    position: relative;
}

.locate-me {
    top: 0;
    right: 5px;
    line-height: 1;
    position: absolute;
    cursor: pointer;
    opacity: 0.5;
}

.locate-me:before {
    display: inline-block;
    content: '';
    width: 20px;
    height: 20px;
    background: url(../images/icon-locate.png) no-repeat;
    background-size: 20px 20px;
}

.locate-me.f-loading:before {
    background: url(../images/loading.png) no-repeat;
    background-size: 20px 20px;
    -webkit-animation: spin 700ms infinite linear;
    -moz-animation: spin 700ms infinite linear;
    animation: spin 700ms infinite linear;
}

.locate-me.f-reset:before {
    background: url(../images/icon-close.png) no-repeat;
    background-size: 20px 20px;
}

/* CSS animations */

@-o-keyframes spin {
    from {
        -o-transform: rotate(0deg);
    }
    to {
        -o-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Selections shortcode */

.facetwp-selections li {
    display: inline-block;
    line-height: 1;
}

.facetwp-selections .facetwp-selection-value {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;

    padding-right: 16px;
    background-image: url(../images/icon-close.png);
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: right center;
}
