
/* Hero banner */

.hero-top {
  min-height: 265px;



  h1 {
    font-size: 2.25rem;
    font-weight: 700;
  }

  h3 {
    font-size: 1.5rem ;
    font-weight:normal;
  }

  @include media-breakpoint-down(sm) {
    h1 {
      font-size: 1.75rem;
      font-weight: 700;
    }

    h3 {
      font-size: 1.2rem ;
      font-weight:normal;
    }
   }

}


/* Borders */

.border-top-darkgrey {
  border-top:1px solid $dark-gray;
}

.border-white {
  border-color: $white;
}

.border-orange {
  border-color: $brand-primary !important;
}



.border-white-top {
  border-top:1px solid $white;
}

.border-white {
  border:1px solid $white;
}

.border-orange-144 {
  border-top:2px solid $brand-primary;
  width:170px;
}

.border-orange-33 {
  border-top:2px solid $brand-primary;
  width:170px;
}

// .border-orange {
//   border:1px solid $brand-primary !important;
// }



/* Backgrounds */
.bg-white {
  background:$white;
}


.bg-orange {
  background:$brand-primary;
}

.bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.8);
}

.bg-dark-gray{
  background:$dark-gray;
}

.bg-gray {
  background:$gray;
}

.bg-light-gray{
  background:$light-gray;
}

.bg-light-light-gray {
  background:$ligt-ligt-gray;
}
.bg-black {
  background:#000000;
}
/* Distributor finder section */
.block-where-to-buy {

  .wpsl-search {
    background-color:$white;
  }

#wpsl-result-list li p {

    margin-bottom:0px;
    padding-bottom:0px;

}
  .wpsl-direction-wrap {
    display:none;
  }

  #wpsl-search-input {
      width: 180px;
      height: auto;
      padding: 4px 12px;
      font-size: 100%;
      margin: 0;
  }

@include media-breakpoint-down(md) {

   #wpsl-search-input {
    width: 280px !important;
    height: auto;
    padding: 4px 12px;
    font-size: 100%;
    margin: 0;
  }
}

#wpsl-search-wrap input, #wpsl-search-btn {
  border-radius: 0;
}

#wpsl-search-wrap .wpsl-input label {
  margin-top:4px;
}
}

/* Product sections */


#product-specs {


  // background-color:$white;
  .nav-tabs {
    border-bottom: 0px solid #f1f1f1;
    border-radius: 0;
  }

  .nav-tabs .nav-link {
    background-color:$ligt-ligt-gray;
    border-radius: 0;

    border: 3px solid $light-gray;
    margin-bottom: 1px;
  }


  .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  background-color:$white;
    border: 3px solid $white;
    padding:.5rem 1rem;

  }

  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color:$white;
    color:$brand-primary;
    border-radius: 0;
    border: 3px solid #fff;



  }

//     .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active:first-of-type {
//     background: red;
// }

.nav-tabs .nav-item .nav-link {
    display: block;
    padding:.5rem 1rem .5rem 1rem;
    margin-right: -3px;
}


  .tab-content {
    background-color:$white;
  }

  .nav-tabs {
      display:none;
  }

  @media(min-width:768px) {
      .nav-tabs {
          display: flex;
      }

      .card {
          border: none;
      }

      .card .card-header {
          display:none;
      }

      .card .collapse{
          display:block;
      }
  }

  @media(max-width:767px){
      .tab-pane {
          display: block !important;
          opacity: 1;
      }
  }

}


#product-benefits {


  // .benefit-item:nth-last-of-type(-n+2) {
  //     // border-bottom:0!important;
  // }

@include media-breakpoint-down(sm) {
  .border-sm-down-none {
    .border{
        border:0px solid #fff !important
  }
}
 }


  .container {}


}

#related_products {

  a {

  }

}


/* Product / Category blocks */

section.block-fixtures {

a  {

  h3 {
    cursor: pointer;
  }

  &:hover,
  &.hover {
    text-decoration: none;
    cursor: pointer;
  }

  &:focus,
  &.focus {
      text-decoration: none;
      cursor: pointer;
  }

  &:active,
  &.active {
    text-decoration: none;
    cursor: pointer;
}


  .mobile-cat {

    border-top:1px solid #ffffff;
    border-bottom:1px solid #ffffff;

    .bg-white {
      background:none;
    }



  }
}

@include media-breakpoint-down(sm) {

    .mobile-cat {

      border-top:1px solid #fff;
      border-bottom:1px solid #fff;
      margin-bottom:-1px;

      .bg-white {
        background:none;
      }



    }
}

.product-block-height {
  height: 480px;
}
@include media-breakpoint-only(sm) {
  .product-block-height {
        height: 248px;
  }
}

@include media-breakpoint-only(md) {
  .product-block-height {
        height: 248px;
  }
}

@include media-breakpoint-only(xs) {
  .product-block-height {
    height: auto;
  }
}

@include media-breakpoint-down(sm) {
  .product-block:first-child {
    border-top:1px solid $white;
  }
  .product-block {
    border-bottom:1px solid $white;

  }
  .product-block-height {
    height: auto;
  }
  .bg-white {
    background:transparent !important;

  }
  a > h3 {
    font-size:1.2em;
  }
}
}




/* USPS */

.usps {

  font-size: .9em;

   span.icon-thick {
    background:transparent url('../images/icon-thick.svg') no-repeat;
    background-position: center left;
    border:0px solid #ffffff;
    padding-left:30px;


  }
}


/* section: Publications */
.publication-block {
  height: 434px;
}

@include media-breakpoint-down(md) {
  .publication-block {
    height: auto;
  }
}

.publication-source {
  font-size:.9em;

  a:link {
    color:inherit;
  }
}

/* Section Where to buy */

.wpsl-store-location {
  @extend .pb-3;
  @extend .mb-3;
  @extend .mr-3;
  border-bottom:1px solid $brand-primary;
}


/* NEWS SECTION */


.news-list {


  ul li a {


      background:url('../images/btn-link-arrow-right-white.png') no-repeat center left;
    font-weight: 400;
    color: #4a4a4a;
    text-decoration: none;


&:hover,
&.hover {
  text-decoration: none;
  font-weight: 700;

}

&:focus,
&.focus {
  color:$brand-primary;
  text-decoration: none;
  font-weight: 700;
}

&:active,
&.active {
  text-decoration: none;
  font-weight: 700;
}
  }


}


.table thead  {
    vertical-align: bottom;
    border-top: 0px solid $brand-primary;
      border-bottom: 0px solid $brand-primary;
}

.table td, .table th {
    padding: .5rem .2rem .5rem 0rem;
    border-top: 0px solid $brand-primary;
    border-top: 0px solid $brand-primary;
}

.table thead th {
  border:0px;
}

.table tr {
  border-top: 0px solid $brand-primary;
  border-bottom: 1px solid $brand-primary;
}
