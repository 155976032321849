//Print settings
$enable-print-styles: true;

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #FFA400; //orange

$white:                 #ffffff;
$light-gray:            #F1F1F1;
$ligt-ligt-gray:        #FAFAFA;
$gray:                  #D8D8D8;
$dark-gray:             #4A4A4A;
$darker-gray:           #9B9B9B;
$darkest-gray:          #222222;



$body-bg:       $light-gray  !default;
// $body-bg:       $white  !default;
$body-color:    $dark-gray  !default;

// Fontsfont-family:
$font-family-sans-serif: 'PT Sans', sans-serif;
$font-weight-normal: 400 !default;
$font-size-base: 0.9375rem;
$line-height-base: 1.43 !default;

$font-weight-bold: 400 !default;

$headings-color:         $dark-gray;

$h1-font-size: 1.75rem; //28px
// $h2-font-size: 1.5rem; // 24px
$h2-font-size: 1.375rem; // 18px edit DK GAVITA
$h3-font-size: 1.125rem; // 18px
$h4-font-size: 1.125rem; // 18px
$h5-font-size: 1rem; // 16px
$h6-font-size: 1rem; // 16px



// Block
$block-color:              #fff;
$block-bg:                 #fff;
$block-color-inverted:     #333;
$block-bg-inverted:        #F1F1F1;
$block-title-font-size-sm: $font-size-base * 4;
$block-title-font-size:    $font-size-base * 3;
$block-lead-font-size-sm:  $font-size-base * 1.25;
$block-lead-font-size:     $font-size-base * 1.15;
$block-vertical-padding:   60px;

/* Rectangle: */

// Buttons
$btn-font-size:      1rem;
$btn-font-weight:    400;
$btn-text-transform: none;
$btn-letter-spacing: normal;
$btn-padding-x-xs:   0.063rem !default; // TODO: Change this to REMs since most of v4 in REMS
$btn-padding-y-xs:   0.513rem !default; // TODO: Change this to REMs since most of v4 in REMS

//$btn-toolbar-divider-bg: $gray-lighter;

$btn-primary-bg:     $brand-primary;
$btn-primary-border: $brand-primary;
$link-color:         $brand-primary;
$link-hover-color:   #000;


$btn-primary-bg:      $brand-primary;
$btn-focus-box-shadow:    $brand-primary;
$btn-active-box-shadow: $brand-primary;

$btn-border-radius: 4px;
$btn-border-radius-lg: 4px;
