header {

  .nav-top {
    a {

      @extend .nav-item;
      @extend .nav-link;
      font-size: 0.875rem; //14px;
      color:$dark-gray;

      &:hover,
      &.hover {
        color:$dark-gray;
        font-weight:700;
      }

      &:focus,
      &.focus {
         color:$brand-primary;
        font-weight:700;
      }

    }

    .active a,
   {
       color:$brand-primary;
       font-weight:700;
   }


  }

  .nav-main {

    .active a,
   {
       color:$brand-primary;
       @extend .t-bold;
   }

    a {
      font-size: 1rem; //16px
      color:$dark-gray;
      text-decoration: none;


      &:hover,
      &.hover {
        // color:$brand-primary;
        font-weight:700;
      }

      &:focus,
      &.focus {
      font-weight:700;
      }

      &:active,
      &.active {
        color:$brand-primary;
          font-weight:700;
    }
  }
}

  .search-form{
    width:212px;

    input,
    .btn-search {
      border:0px;
      background:$light-gray;
    }

    input {
      font-size: 0.875rem; //14px;
    }

    .btn-search {

      background:$light-gray url('../images/icon-search.svg') no-repeat;
      border:0px solid #ffffff;
      background-position: center center;

      &:hover,
      &.hover {
        background:$light-gray url('../images/icon-search.svg') no-repeat;
        border:0px solid #ffffff;
        background-position: center center;
      }

      &:focus,
      &.focus {
        border:0px solid #ffffff;
      }

      &:active,
      &.active {
        border:0px solid #ffffff;
    }

    }



  }


.payoff-bar-bg {
  background: #d8d8d8;
     overflow: hidden;
     position: relative;
}

.topnav-bar-bg {
  display: block;
      position: absolute;
      left: 0px;
      width: 30%;
      height: 100%;
      background-color: #000000;


}

@include media-breakpoint-only(xs) {
  .topnav-bar-bg,
  .payoff-bar-bg,
  .bg-gray  {
    background:  #000000;
  }

}
@include media-breakpoint-only(sm) {

  .payoff-bar-bg {
    background: #d8d8d8;
  }

  .topnav-bar-bg {
    background:  #000000;
  }
}


.menu-toggler {
  background:$white url('../images/hamburger.svg') no-repeat;
  border:0px solid #ffffff;
  background-position: center center;

  &:hover,
  &.hover {
    background:$white url('../images/hamburger.svg') no-repeat;
    border:0px solid #ffffff;
    background-position: center center;
  }

  &:focus,
  &.focus {
    background:$white url('../images/hamburger.svg') no-repeat;
    border:0px solid #ffffff;
    background-position: center center;

  }

  &:active,
  &.active {
    background:$white url('../images/hamburger.svg') no-repeat;
    background-position: center center;
    border:0px solid #ffffff;
}

}
}

// .arrow-up {
//   width: 0;
//   height: 0;
//   border-left: 25px solid transparent;
//   border-right: 25px solid transparent;
//
//   border-bottom: 25px solid rgba(0,0,0,.15);
// }



.sticky-top-header {
  position: -webkit-sticky !important;
  position: sticky;
  top: -1px;

z-index: 1020;
}
