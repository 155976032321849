
/* Buttons */

.btn-min-170 {
  min-width:170px;
}

.btn {
  padding-left:1.4em;
  padding-right:1.4em;
  padding-top:.8em;
  padding-bottom:.8em;

}




.btn-primary {
  background:$btn-primary-bg;
  border-color:$brand-primary;
  font-weight: 700;

  &:hover,
  &.hover {
    background:#FFB633;
      border:1px solid $brand-primary;
  }

  &:focus,
  &.focus {
    background:#FFB633;
    border:1px solid $brand-primary;
    box-shadow: none;
  }

  &:active,
  &.active {
    background:#FF6B00;
}

}

.btn-secondary {
  background:transparent;
  border:1px solid #FFA400;
  color:$brand-primary;
  font-weight: 700;

  &:hover,
  &.hover {
  border:1px solid #FFB633;
    color:$brand-primary;
  background:transparent;


  }

  &:focus,
  &.focus {
      border:1px solid #FF6B00;
      color:$brand-primary;
      box-shadow: none;

  font-weight:700;
  color: #FFA400;


  }

  &:active,
  &.active {
    border:1px solid #FFB633;
      color:$brand-primary;
}

}

.btn-tertiary {
  background:transparent;
  border:1px solid #ffffff;
  color:$white;
  font-weight: 700;

  &:hover,
  &.hover {
  border:1px solid #FFB633;
  }

  &:focus,
  &.focus {
      border:0px solid $white;
      box-shadow: none;
  }

  &:active,
  &.active {
    border:1px solid #FF6B00;
}

}
 .hero-top {

   .btn-tertiary {
     background:transparent;
     border:1px solid #ffffff;
     color:$white;
     font-weight: 700;

     &:hover,
     &.hover {
    background: rgba(255,255,255,0.50);
    border: 1px solid #FFFFFF;

     }

     &:focus,
     &.focus {
       background: rgba(255,255,255,0.50);
         box-shadow: none;
     }

     &:active,
     &.active {
       background: rgba(255,255,255,0.50);
       border: 1px solid #FFFFFF;
   }

   }
 }

.btn-link {

  background:url('../images/btn-link-arrow-right.svg') no-repeat;
  border:0px solid #ffffff;
  color:$dark-gray;
  background-position: center right;
text-align: left;

  padding-left:0em;
  padding-right:1.3em;
  padding-top:0em;
  padding-bottom:0em;

  &:hover,
  &.hover {

    background:url('../images/btn-link-arrow-right.svg') no-repeat;
    border:0px solid #ffffff;
    color:$dark-gray;
    background-position: center right;

  border:0px solid $white;
  }

  &:focus,
  &.focus {
    border:0px solid #FFB633;
    box-shadow: none;
  }

  &:active,
  &.active {
    border:0px solid #FF6B00;
}

}



#mobile-collapse-menu .product-section {

  background:$brand-primary url('../images/arrow-down-thin-white.svg') no-repeat;
    background-position: 94%;

  border-bottom:0px solid #ffffff;
  color:$dark-gray;
  background-position: 94%;

  padding:0;

h3 {
color:#fff;
}
  &:hover,
  &.hover {
  border:0px solid #FFB633;
  }

  &:focus,
  &.focus {
    border:0px solid #FFB633;
    box-shadow: none;


  }

  &:active,
  &.active {
    border:0px solid #FF6B00;

}

}

#mobile-collapse-menu .collapsed {

  background:  url('../images/arrow-down-thin.svg') no-repeat;
  border:0px solid #ffffff;
  color:$dark-gray;
  background-position: 94%;


  padding:0;

  h3 {
    color:$dark-gray;
  }

  &:hover,
  &.hover {
  border:0px solid #FFB633;
  }

  &:focus,
  &.focus {
    border:0px solid #FFB633;
    box-shadow: none;
  }

  &:active,
  &.active {
    border:0px solid #FF6B00;
}

}

.btn-primary.active, .btn-primary:active, .gform_page .gform_page_footer .show>input.dropdown-toggle[type=submit].button, .gform_page .gform_page_footer input.active[type=submit].button, .gform_page .gform_page_footer input[type=submit].button:active, .gform_wrapper .gform_footer .show>input.dropdown-toggle[type=submit].button, .gform_wrapper .gform_footer input.active[type=submit].button, .gform_wrapper .gform_footer input[type=submit].button:active, .show>.btn-primary.dropdown-toggle {

    background-color: $brand-primary;
    background-image: none;
    border-color: $brand-primary;
}

.btn-download,
.dropdown-item {
  min-width: 170px;
}


#btn-container-more-features {

  .collapsed {
   background:none;
  }
.btn-more {
border:1px solid $brand-primary;
color:$brand-primary;

  &:hover,
  &.hover {
    border:1px solid $brand-primary;

  background-color: $brand-primary;
  color:$white;
  }

  &:focus,
  &.focus {

    border:1px solid $brand-primary;

background-color: $brand-primary;
color:$white;
  }

  &:active,
  &.active {
    border:1px solid $brand-primary;

    background-color: $brand-primary;
    color:$white;

}
}

}

@include media-breakpoint-down(xs) {
  .btn-primary,
  .btn-secondary,
  .btn-download {
    display: block;
    width: 100%;
  }
 }
