

.navbar-nav>li>.dropdown-menu {
  margin-top: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.navbar-default .navbar-nav>li>a {
  width: 200px;
  font-weight: bold;
}

.mega-dropdown {
  position: static !important;
  width: 100%;
}

.mega-dropdown-menu {
  padding-bottom: 20px;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: none;
  -webkit-box-shadow: none;
  position:relative;
}


.mega-dropdown-menu > li > ul {
  padding: 0;
  margin: 0;
}

.mega-dropdown-menu > li > ul > li {
  list-style: none;
}

.mega-dropdown-menu > li > ul > li > a {
  display: block;
  padding: 3px 0px;
  clear: both;
  font-size: .9em; // 24px
  font-weight: normal;
  line-height: 1.428571429;
  color: #4a4a4a;
  white-space: normal;
}

.mega-dropdown-menu > li ul > li > a:hover {

  text-decoration: none;
  font-weight: 700;
}

.mega-dropdown-menu > li ul > li > a:focus {
color:$brand-primary;
text-decoration: none;
font-weight: 700;
}

.mega-dropdown-menu .dropdown-header-title,
.mega-dropdown-menu .dropdown-header-subcat {
  color: #4a4a4a;
  font-size: 1.2em; // 24px
  font-weight: 700;
}

.mega-dropdown-menu .dropdown-header-title a {
  font-weight: 700;
}
.mega-dropdown-menu  > li > ul > li.dropdown-header-subcat > a {
  font-size: .9em; // 24px
  font-weight: 700;
}
.mega-dropdown-menu form {
  margin: 3px 20px;
}

.mega-dropdown-menu .form-group {
  margin-bottom: 3px;
}

.dropdown-menu {


    border-top: 1px solid rgba(0,0,0,.15);
border-radius:0;
    // -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    // box-shadow: 0 6px 12px rgba(0,0,0,.175);
  }
