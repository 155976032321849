/* ACF Video BLOCKS */


 .facetwp-facet {
   margin-bottom:0;

}

.facetwp-facet-choose_video_category {
  @extend .row;
  @extend .pl-3;

  .facetwp-checkbox {
    @extend .col-12;
    @extend .col-sm-3;
    @extend .pl-4;
  }
}


// /* Embedded container */
// .embed-container {
// 		position: relative;
// 		// padding-bottom: 56.25%;
// 		overflow: hidden;
// 		max-width: 100%;
// 		height: auto;
// 	}
// //
// // 	.embed-container iframe,
// // 	.embed-container object,
// // 	.embed-container embed {
// // 		position: absolute;
// // 		top: 0;
// // 		left: 0;
// // 		width: 100%;
// // 		height: 100%;
// // 	}


.no-frameborder {
  border:none;
}


.embed-video { position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0; }
.embed-video iframe,
.embed-video object,
.embed-video embed {

  position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }
