
/* Google Font FIX */
body {
    -webkit-animation-delay: 0.1s;
    -webkit-animation-name: fontfix;
    -webkit-animation-duration: 0.1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
}

@-webkit-keyframes fontfix {
    from { opacity: 1; }
    to   { opacity: 1; }
}

/* Main menu */

// a.nav-link {
//   color:#4A4A4A;
//   font-weight: 400;
//
//   &:hover,
//   &.hover {
//   color:$brand-primary;
//   }
//
//   &:focus,
//   &.focus {
//       color:$brand-primary;
//   }
//
//   &:active,
//   &.active {
//       color:$brand-primary;
//       font-weight: 400;
// }
// }



/* Text colors */



/* Titles */

h1,
.h1 {
  font-size: $h1-font-size;
    font-weight:700;
}

h2,
.h2 {
  font-size: $h2-font-size;
    font-weight:700;
}

h3,
.h3 {
  font-size: $h3-font-size;
    font-weight:700;
}

h4,
.h4 {
  font-size: $h4-font-size;
    font-weight:700;
}

h5,
.h5 {
  font-size: $h5-font-size;
    font-weight:700;
}

h6,
.h6 {
  font-size: $h5-font-size;
    font-weight:700;
}

.t-white {
  color:$white;
}

.t-bold {
  font-weight:700;
}

.t-italic {
  font-style: italic;
}


/* Breadcrumb */
.breadcrumbs {

    font-size:.8em;
}
.breadcrumbs > span > a {
  color:$dark-gray;

}

/* Borders */
/* Footer */

/* Footer */

footer {

  a,
  p {
    color:#9B9B9B;
  }

  .footer-social > li > a {

    width:32px;
    height:32px;

    display:block;
  }
}

/* change selectors to fit your HTML */
#page #menu,
#my-menu:not( .mm-menu ) {
   display: none;
}

  #menu:not( .mm-menu ) {
     display: none;
  }

  .tab-content {
    font-size:.9em;
  }

  /* Embedded container */
  .embed-container {
  		position: relative;
  		overflow: hidden;
  		max-width: 100%;
  		height: auto;
  	}

  	.embed-container iframe,
  	.embed-container object,
  	.embed-container embed {
  		position: absolute;
  		top: 0;
  		left: 0;
  		width: 100%;
  		height: 100%;
  	}
