footer {
  border-top:2px solid $darker-gray;


    h4

     {
      color: $darker-gray;
      font-size:.8rem;



    }

    a {
      color: $darker-gray;
      font-size:.8rem;
      text-decoration: none;


      &:hover,
      &.hover {
        // color:$brand-primary;
        font-weight:700;
        text-decoration: none;
      }

      &:focus,
      &.focus {
        font-weight:700;
      }

      &:active,
      &.active {
        color:$brand-primary;
    }

}

    .bottom-navigation p {
        font-size:.8rem;
    }

}
